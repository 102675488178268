
import { defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { AnimalFeedListModel } from "@/domain/animal/animalFeed/animalFeedList/model/AnimalFeedListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: { Vaccinate, AddWeight, AddDisease, Feed, AnimalTransfer },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const animalFeedList = ref<AnimalFeedListModel[]>([]);
    const animalController = store.state.ControllersModule.animalController;
    const animalId = route.params["id"];
    const isLoading = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newFood,
      () => {
        if (store.getters.newFood) getAnimalFeedList(1);
      }
    );
    
    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      getAnimalFeedList(1);
    });

    const getAnimalFeedList = (page) => {
      isLoading.value = true;

      animalFeedList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalFeedListModel: AnimalFeedListModel = {
        feed: {
          animalId: Number(animalId),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalFeedList(animalFeedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalFeed) => {
              totalItems.value = animalFeed.pagination.total;
              currentPage.value = animalFeed.pagination.page;
              pageCount.value = animalFeed.pagination.pageCount;
              animalFeedList.value.push(animalFeed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      PAGE_TITLE_PATH,
      pageSize,
      currentPage,
      totalItems,
      pageCount,
      animalId,
      animalFeedList,
      getAnimalFeedList,
      isLoading,
    };
  },
});
