<template>
  <div class="card">
    <div class="card-body pt-0">
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/info'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.info") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/weights'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.weight") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/vaccines'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/diseases'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.diseases") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/feeds'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.feed") }}
            </router-link>
          </li>
          <!--end::Nav item-->

          <!--
                <li class="nav-item">
                  <router-link
                    to="/animalsVaccine"
                    class="nav-link text-active-primary me-6"
                    active-class="active"
                  >
                    {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-active-primary me-6"
                    to="/feed"
                    active-class="active"
                  >
                    {{ $t("menuPages.animalInfo.tabMenu.feed") }}
                  </router-link>
                </li>
                              -->
        </ul>
      </div>

      <div class="card-header border-0 pt-2" style="padding-left: 0">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">{{
            $t("menuPages.animalFeeds.pageName")
          }}</span>
        </h3>
      </div>
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="animalFeedList.length > 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.animalFeeds.completedDate") }}</td>
                <td>{{ $t("menuPages.animalFeeds.foodName") }}</td>
                <td>{{ $t("menuPages.animalFeeds.amount") }}</td>
                <td>{{ $t("menuPages.animalFeeds.description") }}</td>
                <!--<td>{{ $t("common.table.process") }}</td>-->
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr
                class="odd"
                v-for="(animalFeed, index) in animalFeedList"
                :key="animalFeed.feed.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    new Date(animalFeed.feed.completedDate).toLocaleDateString(
                      "tr-TR"
                    )
                  }}
                </td>
                <td>{{ animalFeed.feed.productName }} 
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    :title=animalFeed.feed.productDesc
                  ></i></td>
                <td>{{ animalFeed.feed.amount }} {{ animalFeed.feed.unitTypeName }}</td>
                <td>{{ animalFeed.feed.description }}</td>
                <!--
                    <td>
                      <a
                        href="#"
                        class="
                          btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                        "
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/general/gen027.svg"
                          />
                        </span>
                      </a>
                    </td>-->
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
            />
          </div>
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.animalFeeds.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="animalFeedList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getAnimalFeedList(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getAnimalFeedList(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getAnimalFeedList(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getAnimalFeedList(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
  </div>
  <Vaccinate></Vaccinate>
  <AddWeight></AddWeight>
  <AddDisease></AddDisease>
  <Feed></Feed>
  <AnimalTransfer></AnimalTransfer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { AnimalFeedListModel } from "@/domain/animal/animalFeed/animalFeedList/model/AnimalFeedListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: { Vaccinate, AddWeight, AddDisease, Feed, AnimalTransfer },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const animalFeedList = ref<AnimalFeedListModel[]>([]);
    const animalController = store.state.ControllersModule.animalController;
    const animalId = route.params["id"];
    const isLoading = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newFood,
      () => {
        if (store.getters.newFood) getAnimalFeedList(1);
      }
    );
    
    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      getAnimalFeedList(1);
    });

    const getAnimalFeedList = (page) => {
      isLoading.value = true;

      animalFeedList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalFeedListModel: AnimalFeedListModel = {
        feed: {
          animalId: Number(animalId),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalFeedList(animalFeedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalFeed) => {
              totalItems.value = animalFeed.pagination.total;
              currentPage.value = animalFeed.pagination.page;
              pageCount.value = animalFeed.pagination.pageCount;
              animalFeedList.value.push(animalFeed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      PAGE_TITLE_PATH,
      pageSize,
      currentPage,
      totalItems,
      pageCount,
      animalId,
      animalFeedList,
      getAnimalFeedList,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
